import React from "react";
import Typed from "react-typed";
import SEO from "./SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "./HeaderTwo";
import FooterTwo from "./FooterTwo";
import Copyright from "./Copyright";
import AboutOne from "./AboutOne";
import ServiceFive from "./ServiceFive";
import CalltoActionFive from "./CalltoActionFive";
import TeamTwo from "./TeamTwo";
import TestimonialThree from "./TestimonialThree";
import SectionTitle from "./SectionTitle";
import Separator from "./Separator";
import Mission from "./Mission";
import BlogList from "./BlogList";
import BlogClassicData from "./BlogList.json";
import HeaderOne from "../contact/HeaderOne";
var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Business Consulting 02" />
      <main className="page-wrapper">
      <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">A.K CONSULTING AGENCY</span>
                  <h1 className="title theme-gradient display-two">
                    A.K CONSULTING <br />{" "}
                    <Typed
                      strings={["CDSCO", "Cosmetic.", "Medical Devices."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  {/* <p className="description">
                    We help our clients succeed by creating brand identities,
                    digital experiences, and print materials.
                  </p> */}
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

    
      </main>
    </>
  );
};
export default BusinessConsulting2;
