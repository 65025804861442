import React from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';
import medical from '../Images/medical3.jpg' 

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src={medical} alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title"><br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            " New Guidance of CDSCO",
                                          
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>
                                    
                                </p>
                                <p>As per the new Guidance of CDSCO, suspension and cancellation of the Class A & B medical devices may happen if importer fails to meet the deadline specified by CDSCO. CDSCO has set a complete procedure for granting license for medical devices imported in the country. This procedure is applicable when we import medical devices from other countries to India.</p>
                                <p>However, they need to be classified according to CDSCO notified devices list, Earlier manufacturer were able to sell their medical device in India without following any specific rules and regulations but from 2006, medical devices entering to India need to follow specific import guidelines set by CDSCO.  </p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default" to='/contact'><span>Contact Us</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
