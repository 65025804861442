import React from "react";
import Typed from "react-typed";
import SEO from "./SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "./HeaderTwo";
import FooterTwo from "./FooterTwo";
import Copyright from "./Copyright";
import About1One from "./About1One";
import Service1Five from "./Service1Five";
import Callto1ActionFive from "./Callto1ActionFive";
import Callto2ActionFive from "./Callto2ActionFive";
import Team1Two from "./Team1Two";
import TestimonialThree from "./TestimonialThree";
import SectionTitle from "./SectionTitle";
import Separator from "./Separator";
import Mission from "./Mission";
import Blog1List from "./Blog1List";
import BlogClassicData from "./BlogList.json";
import HeaderOne from "../contact/HeaderOne";
var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Medical Devices" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 bg-transparent height-850"
          style={{ height: "545px",
            display: "flex",
            alignContent: "center",
            alignItems: "center" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle"></span>
                  <h1
                    className="title theme-gradient display-two"
                    
                  >
                    Medical Devices <br />{" "}
                  </h1>

                  <div className="button-group"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <About1One />
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container" >
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Medical Device Import License Proces"
                  title="Pre-requisites for registration process: "
                />
                <ul className="ull" style={{listStyle: "square",
    marginBottom: "30px",
    paddingLeft: "20px"}}>
                  <li>Generic Name / Brand Name </li>
                  <li>Intended Use </li>
                  <li>Mode of application </li>
                  <li>
                    Study of device details and Classification of medical device
                    on the basis of notified product list of CDSCO.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <Service1Five serviceStyle="gallery-style" textAlign="text-left" />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area">
          <div className="wrapper">
            <Callto1ActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        {/* Start Team Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Phases in CDSCO Import License Registration:"
                  title="Phase I – Applicant Registration "
                  description="After applying Client will have an active account on CDSCO online registration portal .An authorized agent (who must have wholesale license for local distribution) having license to manufacture for sale or distribution or wholesale license for sale or distribution under these rules, shall make an application for grant of import license for medical device to the Central Licensing Authority through an identified online portal of the Ministry of Health and Family Welfare in the Central Government in Form MD-14 for obtaining a license.  "
                  subtitle1="Procedure of phase 1 "
                  li1="Online form submission."
                  li2="Submission of required document."
                  li3="Approval from CDSCO. "
                  li4="If rejected, CDSCO will communicate the reason behind <br/> rejection or reapplication if necessary. "

                 
                  title1="Phase II – Import License application"
                  description1="Submission of online form with necessary details like classification of<br/> device, brand name, intended use, product description, etc. <br/><br/>
                  Uploading the documents. <br/><br/>
                  Fees to be paid. <br/><br/>
                  Change in the status of the application.<br/> <br/>
                  If any query raised, then proper justification or revised documents needs to be uploaded.   "
                />
              </div>
              <Callto2ActionFive />
            </div>

            <Team1Two
              column="col-lg-4 col-md-6 col-12"
              teamStyle="team-style-default style-two"
            />
          </div>
        </div>
        {/* End Team Area  */}
        <div>
                {/* Start Service Area  */}
        <div className="rn-service-area n-section-gap" >
          <div className="container" >
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Competencies"
                  // title="Pre-requisites for registration process: "
                />
                <ul className="ull" style={{listStyle: "square",
    marginBottom: "30px",
    paddingLeft: "20px"}}>
                  <li>We reduce our client’s costs and efforts.  </li>
                  <li>We have Strong relations with regulated bodies. </li>
                  <li>We also have dedicated and experienced regulatory team. </li>
                  <li>
                  We represent on behalf of our clients for Audits of FDA, ISO & CE {" "}
                  </li>
                  <li>Competent Technical staff. </li>
                  <p>Many times, rejections or need of re-submission has been seen due to incomplete information or wrongly submission, to avoid rejections and delay in projects get expert guidance and follow a correct procedure for better results. </p>
                </ul>
              </div>
            </div>
            <Service1Five serviceStyle="gallery-style" textAlign="text-left" />
          </div>
        </div>
        {/* End Service Area  */}
        </div>
        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        {/* End Testimonial Area  */}

        <Separator />

        {/* <FooterTwo /> */}
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
